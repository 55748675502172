:root{
    --test : #023c40;
    --footer-color: #363445;
    --decor-color:#CC8770;
    --second-color:#F1EEE9;
    --white:#FFFFFF;
    --font-headers: Cormorant Garamond;
    --font-text: Raleway;
    --main-padding: 0px 120px;
    --mobile-padding: 0px 15px;
    --second-padding: 0px 60px;
}