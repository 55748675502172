.loader{
    //height: 570px;
    background: var(--second-color);
    @media (min-width:768px){
    height: 780px;
    }
    &-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 90px 15px 0 15px;
        @media (min-width:768px){
        padding: 150px 60px 0 60px;
        }
    }
    &-figure{
        margin-bottom: 40px;
        @media (min-width:768px){
        margin-bottom: 50px;
        }
    }
    &-text{
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
        @media (min-width:768px){
        width: 50%;
        margin-bottom: 50px;
        }
    }
    &-button{
        width: 100%;
        @media (min-width:768px){
            width: 300px;
        }
    }
}