@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-MediumItalic.eot');
    src: local('Cormorant Garamond Medium Italic'), local('CormorantGaramond-MediumItalic'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-MediumItalic.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-MediumItalic.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Medium.eot');
    src: local('Cormorant Garamond Medium'), local('CormorantGaramond-Medium'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Medium.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Medium.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-LightItalic.eot');
    src: local('Cormorant Garamond Light Italic'), local('CormorantGaramond-LightItalic'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-LightItalic.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-LightItalic.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.eot');
    src: local('Cormorant Garamond Light'), local('CormorantGaramond-Light'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.eot');
    src: local('Cormorant Garamond SemiBold Italic'), local('CormorantGaramond-SemiBoldItalic'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Bold.eot');
    src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Bold.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Bold.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.eot');
    src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-BoldItalic.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-BoldItalic.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.eot');
    src: local('Cormorant Garamond SemiBold'), local('CormorantGaramond-SemiBold'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.eot');
    src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/Cormorant_Garamond/CormorantGaramond-Regular.eot');
    src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Regular.woff2') format('woff2'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Regular.woff') format('woff'),
        url('./fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-SemiBoldItalic.eot');
    src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
        url('./fonts/Raleway/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-ExtraLightItalic.eot');
    src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
        url('./fonts/Raleway/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-ExtraLightItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-MediumItalic.eot');
    src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
        url('./fonts/Raleway/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-MediumItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-ExtraBoldItalic.eot');
    src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
        url('./fonts/Raleway/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Regular.eot');
    src: local('Raleway'), local('Raleway-Regular'),
        url('./fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Regular.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-ExtraBold.eot');
    src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
        url('./fonts/Raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
        url('./fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-BlackItalic.eot');
    src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
        url('./fonts/Raleway/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-BlackItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-BlackItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Light.eot');
    src: local('Raleway Light'), local('Raleway-Light'),
        url('./fonts/Raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Light.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Light.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Black.eot');
    src: local('Raleway Black'), local('Raleway-Black'),
        url('./fonts/Raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Black.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Black.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Medium.eot');
    src: local('Raleway Medium'), local('Raleway-Medium'),
        url('./fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Medium.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-ExtraLight.eot');
    src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
        url('./fonts/Raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-ExtraLight.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-ExtraLight.woff') format('woff'),
        url('./fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Italic.eot');
    src: local('Raleway Italic'), local('Raleway-Italic'),
        url('./fonts/Raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Italic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Italic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Thin.eot');
    src: local('Raleway Thin'), local('Raleway-Thin'),
        url('./fonts/Raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Thin.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Thin.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-SemiBold.eot');
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
        url('./fonts/Raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
        url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-LightItalic.eot');
    src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
        url('./fonts/Raleway/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-LightItalic.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-LightItalic.woff') format('woff'),
        url('./fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Bold.eot');
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('./fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Raleway/Raleway-Bold.woff2') format('woff2'),
        url('./fonts/Raleway/Raleway-Bold.woff') format('woff'),
        url('./fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

