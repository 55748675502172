.button{
    &-btn{
        background: var(--decor-color);
        width: 100%;
        padding: 18px;
        text-align: center;
        border-radius: 4px;
        color: var(--white);
        font-size: 16px;
        display: block;
        @media (min-width:768px){
        width: 280px;
        padding: 18px 31px;
        }
        &:hover{
            opacity: 0.9;
        }
    }
    &-card{
        background: #F1EEE9;
        width: 100%;
        color: var(--decor-color);
        font-size: 16px;
        padding: 18px 31px;
        border-radius: 4px;
        &:hover{
            background:var(--decor-color);
            color:#F1EEE9;
        }
    }
    &-wrap{
        margin-top: 0;
        @media (min-width:768px){
        margin-top: auto;
        }
        &-nav{
            margin-top: 20%;
            @media (min-width:360px){
                margin-top: 90px;
            }
        }
    }
    &-cardWrap{
        margin: 10px 20px 0 20px;
        @media (min-width:768px){
            margin: 10px 50px 0 50px;
            }
    }
    &-form{
        background: var(--decor-color);
        width: 100%;
        padding: 18px 31px;
        border-radius: 4px;
        color: var(--white);
        font-size: 16px;
        &:hover{
            opacity: 0.9;
        }
    }
    &-disabled{
        background: var(--decor-color);
        width: 100%;
        padding: 18px 31px;
        border-radius: 4px;
        color: var(--white);
        font-size: 16px;
    }
}