.footer{
        display: flex;
       // padding: var(--mobile-padding);
        flex-direction: column-reverse;
        margin-top: 170px;
        position: relative;
    @media (min-width:768px){
        padding: var(--second-padding);
        margin-top: 90px;
        flex-direction: column;
    }
    @media (min-width:1100px){
        padding: var(--main-padding);
        margin-top: 90px;
        flex-direction: row;
    }

    &-titleWrap{
        width: 100%;
        position: absolute;
        top: -120px;
        @media (min-width:768px){
        width: 100%;
        position: static;
        }
        h2{
            text-align: center;
            @media (min-width:768px){
            text-align: left;
            }
        }
    }
    &-text{
        font-weight: 700;
        line-height: 30px;
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin-top: 0;
        @media (min-width:768px){
            text-align: left;   
        }

    }
    &-line{
        display: none;
        @media (min-width:768px){
    display: block;
    height:1px;
    width:76px;
    border-bottom: 3px solid var(--decor-color);
    position: relative;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
    transform: translateX(-3%);
        }
    }
    &-wrap{
        height: 100%;
        width: 100%;
        @media (min-width:768px){
            height: 520px;
            width: 100%;
        }
        @media (min-width:1100px){
            height: 540px;
            width: 65%;
            }
    }
    &-iconsWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width:768px){
        flex-direction: row;
        }
    }
    &-connect{
        max-width: 300px;
        margin-top: 50px;
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (min-width:768px){
            max-width: 390px;
            margin-top: 60px;
            justify-content: end;
        }
        p:not(:last-child){
            margin-bottom: 15px;
        }
    }
    &-icons{
        height: 64px;
        width: 64px;
        background:var(--decor-color);
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width:768px){
            margin-right: 15px;  
        }
        &:hover{
            opacity: 0.9;
        }
    }
    &-contactsWrap{
        display: flex;
        margin-top: 50px;
        margin-bottom: 70px;
        flex-wrap: wrap;
        height: 300px;
        align-items: center;
        @media (min-width:768px){
            flex-wrap: nowrap;
            margin-top: 65px;
            margin-bottom: 0;
            height: auto;
        }
    }
    &-contacts{
        width: 100%;
        text-align: center;
        @media (min-width:768px){
            width: 30%;
            text-align: left;
            height: 80px;
            margin-right: 26px;
        }
        p:not(:last-child){
            margin-bottom: 0;
        }
    }
    &-content{
        font-weight: 500;
        margin: 0 auto;
        width: 52%;
        @media (min-width:768px){
            width: 100%;
        }
    }
    &-map{
        height: 290px;
        width: 290px;
        border: 2px solid var(--decor-color);
        border-radius: 50%;
        overflow: hidden;
        z-index: 2;
        margin: 0 auto;
        @media (min-width:360px){
            height: 325px;
            width: 325px;
        }
        @media (min-width:420px){
            height: 390px;
            width: 390px;
            margin: 0 auto;
        }
        @media (min-width:768px){
            margin-bottom: 50px;
        }
        @media (min-width:1100px){
            margin-bottom: 0px;
        }

    }
    &-p{
        color:wheat;
        font-family: Cormorant Garamond;
    }
}