@font-face {
  font-family: 'icomoon';         
  src:  url('./iconfont/fonts/icomoon.eot');                                          /*fonts/icomoon.eot?fmvqqk*/
  src:  url('./iconfont/fonts/icomoon.eot') format('embedded-opentype'),       /*fonts/icomoon.eot?fmvqqk#iefix*/
    url('./iconfont/fonts/icomoon.ttf') format('truetype'),                           /*fonts/icomoon.ttf?fmvqqk*/
    url('./iconfont/fonts/icomoon.woff') format('woff'),                                /*fonts/icomoon.woff?fmvqqk'*/
    url('./iconfont/fonts/icomoon.svg' ) format('svg');                            /*fonts/icomoon.svg?fmvqqk#icomoon*/
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  //speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-Vector:before {
  content: "\e900";
  color: #cc8770;
}
.icon-Star-1:before {
  content: "\e901";
  color: #cc8770;
}

.icon-Star-2:before{
  content: "\e901";
  color:#cccccc;
}
.icon-Arrow-left:before {
  content: "\e902";
  color: #fff;
}
.icon-Arrow-1-Stroke:before {
  content: "\e903";
  color: #fff;
}