.header{
    background: var(--white);
    padding: var(--mobile-padding);
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    border-bottom: 1px solid var(--second-color);
    @media (min-width:768px){
        padding:0;
    }

    &-link{
        width: 80%;
        display: inline-block;
        @media (min-width:768px){
            width: 100%;
        }
    }

    &-mobile{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        padding: var(--mobile-padding);
        background-color: var(--white);
        z-index: 3;
    }
    &-wrap{
        display: flex;
        justify-content: space-between;
        @media (min-width:768px){
            padding: var(--second-padding);
        }
        @media (min-width:1100px){
            padding: var(--main-padding);
        }
        &-mobile{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            position: relative;
        }
    }
    &-logo{
        padding: 16px 0 12px;
        &-mobile{
            padding: 0px 15px;
        }
    }
}
.navigation{
    display: flex;
    align-items: center;
    &-mobile{
        display: block;
    height: 100%;
    width: 250px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    }
    &-wrap{
        display: none;
        @media (min-width:768px){
            display: flex;
            justify-content: space-between;
        }
        &-mobile{
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 40%;
        }
       
    }
    &-link{
        flex-shrink: 1;
        &-mobile{
            line-height: 45px;
            text-align: center;        
        }
    }
    &-item{
        padding: 0 10px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        position: relative;
        @media (min-width:768px){
            font-size: 13px;
            padding: 0 4px;
        }
            @media (min-width:1024px){
            font-size: 16px;
        }
        @media (min-width:1100px){
            padding: 0 25px;
        }
        &:hover{
            color: black;
            &:before{
                background:var(--decor-color);
            } 
        }
        &:before{
            content:'';
            background:#FFFFFF;     //#CC8770
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, 50%);
            left: 50%;
            top: 100%;

        }
        &-active{
            color: black;
            &:before{
                content:'';
                background:var(--decor-color);
                width: 5px;
                height: 5px;
                border-radius: 50%;
                position: absolute;
                transform: translate(-50%, 50%);
                left: 50%;
                top: 100%;
            } 
        }
    }
    &-mobileButton{
        width: 30px;
        height: 25px;
        display: inline-block;
        margin: 23px 15px 0 0;
        @media (min-width:360px) {
            margin: 30px 15px 0 0;
        }
        @media (min-width:768px) {
            display: none;           
        }
    }
}

//burger`s styles
.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

.hamburger {
    margin: 0 auto;
    //margin-top: 30px;
    width: 30px;
    height: 19px;
    position: relative;
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 3px;
    background-color: var(--decor-color);
    display: block;
    border-radius: 3px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 8px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

.hamburger4 .bar4 {
    top: 8px;
}

.hamburger4 .bar5 {
    bottom: 0px;
}

.hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 13.5px;
}

.hamburger4 .bar3 {
    width: 1px;
    left: 13.5px;
    
}

.checkbox4:checked + label > .hamburger4 > .bar1{
    top: 13.5px;
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2{
    left: 0px;
    width: 24px;
    transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3{
    left: 0;
    width: 24px;
    transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4{
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5{
    bottom: 13.5px;
    background-color: transparent; 
}


