
body * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: var(--font-text);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0px;
  color: #251E5E;
  @media (min-width:360px){
    font-size: 16px;
    line-height: 30px;
  }
}

.spanTitle{
  padding: 0px 10px 10px 0px;
  background-color: transparent;
  @media (min-width:768px){
    background-color: var(--second-color);
    padding: 0px 10px 10px 0px;
  } 
  &-about{
    background-color: var(--second-color);
    padding: 0px 5px 5px 5px;
  }
  &-word{
    padding: 0px 10px 10px 10px;
    background-color: transparent;
    @media (min-width:768px){
      background-color: var(--second-color);
      padding: 0px 10px 10px 10px;
    } 
  }
}

.container{
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  @media (min-width:768px){
    max-width: 1366px;
  }
}
.secondBg{
  background-color: var(--second-color);
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
}

input {
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  background-color: inherit;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

textarea, select {
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  background-color: inherit;
  -webkit-appearance: none;
}

select, label{
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-headers);
  margin: 0;
  font-weight: 600;
}
h1,h2{
  font-size: 45px;
  line-height: 40px;
  @media (min-width:360px){
    font-size: 58px;
    line-height: 55px;
  }
}
h2{
  text-align: center;
}
h3{
  font-size: 32px;
  line-height: 35px;
  @media (min-width:370px){
    font-size: 40px;
    line-height: 45px;
    }
    @media (min-width:768px){
      font-size: 45px;
      line-height: 45px;
      }
}
h4{
    font-size: 40px;
    line-height: 45px;
}

figure {
  margin: 0;
  line-height: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

p:not(:last-child) {
  margin-bottom: 15px;
  @media (min-width:768px) {
    margin-bottom: 20px;
  }
  
}


svg {
  max-width: 100%;
  max-height: 100%;
}